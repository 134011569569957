import { useState } from "react";
import { Typography } from "antd";
import { ActivityCard } from "./ActivityCard";
import { deleteActivity } from "../firebase/firestore/activities";
import { convertWeightUnits } from "../helpers/convertWeightUnits";

const { Title, Text } = Typography;

export const Activity = ({
  activity: { id: activityId, weight, repCount, units: activityUnits },
  isSpecial,
  isSummary,
  units,
}) => {
  const [isDeleteView, setIsDeleteView] = useState(false);

  if (isSummary) {
    const divStyles = { padding: "8px" };

    if (isSpecial) {
      divStyles.border = "3px solid gold";
      divStyles.borderRadius = "10px";
    }

    return (
      <div style={{ display: "inline-block", marginRight: "12px" }}>
        <ActivityCard isSummary>
          <div>
            <Title level={5} style={{ margin: "0 0 -5px 0" }}>
              {convertWeightUnits(weight, activityUnits, units)}
            </Title>
            <Text style={{ opacity: 0.6 }}>x{repCount}</Text>
          </div>
        </ActivityCard>
      </div>
    );
  }

  if (isDeleteView) {
    return (
      <ActivityCard isDeleteView={isDeleteView}>
        <div
          onClick={() => deleteActivity(activityId)}
          style={{
            height: "50%",
            width: "100%",
            backgroundColor: "#AB3F3F",
            borderRadius: "10px 10px 0 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ fontSize: "18px", color: "white" }}>Delete</Text>
        </div>
        <div
          onClick={() => setIsDeleteView(false)}
          style={{
            height: "50%",
            width: "100%",
            backgroundColor: "#D9D9D9",
            borderRadius: "0 0 10px 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ fontSize: "18px" }}>Cancel</Text>
        </div>
      </ActivityCard>
    );
  }

  return (
    <ActivityCard onClick={() => setIsDeleteView(true)}>
      <div>
        <Title level={5} style={{ margin: "0 0 -5px 0" }}>
          {convertWeightUnits(weight, activityUnits, units)}
        </Title>
        <Text style={{ opacity: 0.6 }}>x{repCount}</Text>
      </div>
    </ActivityCard>
  );
};
