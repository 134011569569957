export const ActivityCard = ({
  outline = false,
  isSummary = false,
  isDeleteView = false,
  ...props
}) => {
  let styles = {
    height: "120px",
    width: "80px",
    padding: "16px",
    borderRadius: "10px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  };

  if (isSummary) {
    styles = {
      ...styles,
      height: "60px",
      width: "42px",
      padding: 0,
    };
  }

  if (outline) {
    styles = {
      ...styles,
      border: "2px solid black",
      opacity: "40%",
      backgroundColor: "none",
    };
  }

  if (isDeleteView) {
    styles = {
      ...styles,
      display: "block",
      padding: 0,
    };
  }

  return <div style={styles} {...props} />;
};
