import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { createWorkoutOptimistic } from "../firebase/firestore/workouts";
import { useAuthUser } from "../firebase/auth";

const { Title } = Typography;

export const NewWorkoutButton = () => {
  const authUser = useAuthUser();
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        const workoutId = createWorkoutOptimistic(authUser.uid);
        navigate(`/workouts/${workoutId}`);
      }}
      style={{
        border: "3px solid black",
        borderRadius: "20px",
        padding: "10px",
        marginBottom: "18px",
        textAlign: "center",
        opacity: "40%",
      }}
    >
      <Title level={4} style={{ display: "inline" }}>
        + New Workout
      </Title>
    </div>
  );
};
