const prettyRound = (weight) => {
  // Round to maximum 4 digits and maximum 1 decimal digit
  const roundedWeight = Math.round(weight * 10) / 10;

  const weightArray = roundedWeight.toString().split(".");

  if (weightArray[0].length < 4) {
    return roundedWeight;
  }

  return Math.round(weight);
};

export const convertWeightUnits = (weight, units, preferredUnits) => {
  const factor = 2.2046;

  if (units === "lbs" && preferredUnits === "kg") {
    return prettyRound(weight / factor);
  }

  if (units === "kg" && preferredUnits === "lbs") {
    return prettyRound(weight * factor);
  }

  return weight;
};
