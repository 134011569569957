import { Link, useLocation } from "react-router-dom";
import { Typography } from "antd";
import {
  FireOutlined,
  UserOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const NavButton = ({ Icon, text, to }) => {
  const { pathname } = useLocation();
  const focus = pathname.split("/")[1] === to.split("/")[1];

  return (
    <Link
      to={to}
      style={{
        textDecoration: "none",
        fontWeight: focus ? "bold" : "normal",
        width: "80px",
        textAlign: "center",
      }}
    >
      <Icon
        style={{
          color: "white",
          display: "block",
          fontSize: "18px",
          strokeWidth: focus ? 30 : 10,
          stroke: "white",
        }}
      />
      <Text style={{ color: "white", fontSize: "12px" }}>{text}</Text>
    </Link>
  );
};

export const Navbar = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#7B3D3D",
        height: "60px",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <NavButton to="/workouts" Icon={FireOutlined} text="Workouts" />
      <NavButton to="/graphs" Icon={LineChartOutlined} text="Graphs" />
      <NavButton to="/profile" Icon={UserOutlined} text="Profile" />
    </div>
  );
};
