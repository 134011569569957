export const Page = ({ children, isRoot }) => {
  const padding = 18;

  return (
    <div
      style={{
        width: `calc(100% - ${padding}px - ${padding}px)`,
        minHeight: `calc(100vh - ${padding}px - ${padding}px)`,
        background: "#ededed",
        padding,
      }}
    >
      <div style={{ margin: "0 auto", maxWidth: "500px" }}>{children}</div>
    </div>
  );
};
