import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RootPage } from "./routes/RootPage";
import { SignupPage } from "./routes/SignupPage";
import { SetupPage } from "./routes/SetupPage";
import { WorkoutsPage } from "./routes/WorkoutsPage";
import { WorkoutPage } from "./routes/WorkoutPage";
import { GraphsPage } from "./routes/GraphsPage";
import { ProfilePage } from "./routes/ProfilePage";
import { PublicProfilePage } from "./routes/PublicProfilePage";
import { SettingsPage } from "./routes/SettingsPage";
import { AuthRedirect } from "./components/AuthRedirect";
import { NavPage } from "./components/NavPage";
import { CTAPage } from "./components/CTAPage";
import { Page } from "./components/Page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootPage />,
  },
  {
    path: "/signup",
    element: (
      <Page>
        <AuthRedirect>
          <SignupPage />
        </AuthRedirect>
      </Page>
    ),
  },
  {
    path: "/login",
    element: (
      <Page>
        <AuthRedirect>
          <SignupPage isLogin />
        </AuthRedirect>
      </Page>
    ),
  },
  {
    path: "/setup",
    element: (
      <Page>
        <AuthRedirect authRequired>
          <SetupPage />
        </AuthRedirect>
      </Page>
    ),
  },
  {
    path: "/workouts",
    element: (
      <Page>
        <AuthRedirect authRequired>
          <NavPage>
            <WorkoutsPage />
          </NavPage>
        </AuthRedirect>
      </Page>
    ),
  },
  {
    path: "/workouts/:workoutId",
    element: (
      <Page>
        <AuthRedirect authRequired>
          <NavPage>
            <WorkoutPage />
          </NavPage>
        </AuthRedirect>
      </Page>
    ),
  },
  {
    path: "/graphs",
    element: (
      <Page>
        <AuthRedirect authRequired>
          <NavPage>
            <GraphsPage />
          </NavPage>
        </AuthRedirect>
      </Page>
    ),
  },
  {
    path: "/profile",
    element: (
      <Page>
        <AuthRedirect authRequired>
          <NavPage>
            <ProfilePage />
          </NavPage>
        </AuthRedirect>
      </Page>
    ),
  },
  {
    path: "/profile/settings",
    element: (
      <Page>
        <AuthRedirect authRequired>
          <NavPage>
            <SettingsPage />
          </NavPage>
        </AuthRedirect>
      </Page>
    ),
  },
  {
    path: "/profile/:userId",
    element: (
      <Page>
        <CTAPage>
          <PublicProfilePage />
        </CTAPage>
      </Page>
    ),
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
