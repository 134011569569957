import { NewWorkoutButton } from "../components/NewWorkoutButton";
import { WorkoutsList } from "../components/WorkoutsList";

export const WorkoutsPage = () => {
  return (
    <div>
      <NewWorkoutButton />
      <WorkoutsList />
    </div>
  );
};
