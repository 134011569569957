import { Navbar } from "./Navbar";

export const NavPage = ({ children }) => {
  return (
    <div style={{ marginBottom: "60px" }}>
      {children}
      <Navbar />
    </div>
  );
};
