import { useNavigate } from "react-router-dom";
import { useAuthUser } from "../firebase/auth";
import { Loader } from "../components/Loader";
import { checkIsMissingUserData } from "../helpers/checkIsMissingUserData";

export const AuthRedirect = ({ authRequired, children }) => {
  const authUser = useAuthUser();
  const navigate = useNavigate();

  if (authUser === undefined) {
    return <Loader fullPage />;
  }

  if (authUser === null && authRequired) {
    navigate("/login");
    return <Loader fullPage />;
  }

  if (authUser && !authRequired) {
    const checkUserAndRedirect = async () => {
      const isMissingUserData = await checkIsMissingUserData(authUser.uid);

      if (isMissingUserData) {
        navigate("/setup");
      } else {
        navigate("/workouts");
      }
    };

    checkUserAndRedirect();

    return <Loader fullPage />;
  }

  return children;
};
