import { Link } from "react-router-dom";
import { Typography } from "antd";

const { Title } = Typography;

export const CTAPage = ({ children }) => {
  return (
    <div style={{ marginBottom: "60px" }}>
      {children}
      <Link to="/">
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            background: "linear-gradient(16deg, #7B3D3D 10%, #47AFB6 100%)",
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title level={3} style={{ margin: 0, color: "white" }}>
            Join the 1000 pound club! 💪
          </Title>
        </div>
      </Link>
    </div>
  );
};
