import { fetchUser } from "../firebase/firestore/users";

export const checkIsMissingUserData = async (uid) => {
  const user = await fetchUser(uid);

  if (!user || !(user.firstName && user.lastName)) {
    return true;
  }

  return false;
};
