import { Button, Spin } from "antd";

export const ButtonWithLoader = ({ loading, onClick, children, ...props }) => {
  return (
    <div style={{ textAlign: "right" }}>
      {loading && <Spin style={{ marginRight: "12px" }} />}
      <Button type="primary" disabled={loading} onClick={onClick} {...props}>
        {children}
      </Button>
    </div>
  );
};
