import { Spin } from "antd";
import { OnboardingContainer } from "./OnboardingContainer";
import { Center } from "./Center";

export const Loader = ({ fullPage }) => {
  if (fullPage) {
    return (
      <OnboardingContainer style={{ textAlign: "center" }}>
        <Spin />
      </OnboardingContainer>
    );
  }

  return (
    <Center>
      <Spin />
    </Center>
  );
};
