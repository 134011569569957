import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
  TimeScale,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
  TimeScale,
  zoomPlugin
);

const dayFactor = 24 * 60 * 60 * 1000;

export const WeightOverTimeGraph = ({ title, chartData }) => {
  const minDate = chartData[0].x;
  const maxDate = chartData[chartData.length - 1].x;
  const minWeight = Math.min(...chartData.map(({ y }) => y));
  const maxWeight = Math.max(...chartData.map(({ y }) => y));
  const roundedMinWeight = Math.round(minWeight / 50) * 50;
  const roundedMaxWeight = Math.round(maxWeight / 50) * 50;
  const yMin =
    roundedMinWeight > minWeight ? roundedMinWeight - 50 : roundedMinWeight;
  const yMax =
    roundedMaxWeight < maxWeight ? roundedMaxWeight + 50 : roundedMaxWeight;

  const options = {
    plugins: {
      title: {
        display: true,
        text: title,
      },
      legend: {
        display: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          pinch: {
            enabled: true,
          },
          wheel: {
            enabled: true,
          },
          mode: "x",
        },
        limits: {
          x: {
            min: "original",
            max: "original",
            minRange: 7 * dayFactor,
          },
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          unitStepSize: 1,
          displayFormats: {
            day: "MMM dd",
          },
        },
        min: minDate,
        max: maxDate,
      },
      y: {
        min: yMin,
        max: yMax,
      },
    },
    maintainAspectRatio: false,
  };

  const data = {
    datasets: [
      {
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        data: chartData,
      },
    ],
  };

  return (
    <div
      style={{
        minHeight: "200px",
        height: "calc(100vh - 60px - 40px - 24px - 18px - 18px - 120px)",
      }}
    >
      <Line options={options} data={data} />
    </div>
  );
};
