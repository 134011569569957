import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "antd";
import { formatDistance } from "date-fns";
import { useSubscribeUser } from "../firebase/firestore/users";
import { useLastWorkout } from "../firebase/firestore/workouts";
import { useMaxActivities } from "../firebase/firestore/activities";
import { Loader } from "../components/Loader";
import { WeightProgress } from "../components/WeightProgress";
import { WeightBreakdown } from "../components/WeightBreakdown";
import { convertWeightUnits } from "../helpers/convertWeightUnits";

const { Title, Text } = Typography;

export const PublicProfilePage = () => {
  let { userId } = useParams();

  // Hardcode giving myself the url "/profile/alex"
  userId = userId === "alex" ? "QxPGQR8WeVa3SxfFrVouqNF8uKI2" : userId;
  // Hardcode giving Nikhil the url "/profile/nikhil"
  userId = userId === "nikhil" ? "aGKiCz571MMM8oL0EQc2VBdAnua2" : userId;

  const units = "lbs";

  const {
    user: { firstName, lastName },
    loading: loadingUser,
  } = useSubscribeUser(userId);
  const {
    workout: { workoutDate: lastWorkoutDate },
    loading: loadingWorkout,
  } = useLastWorkout(userId);
  const { maxActivities, loading: loadingActivities } =
    useMaxActivities(userId);

  const convertedMaxActivites = useMemo(() => {
    if (
      !maxActivities.bench ||
      !maxActivities.squat ||
      !maxActivities.deadlift
    ) {
      return {};
    }

    const newMaxActivities = {
      bench: { ...maxActivities.bench },
      squat: { ...maxActivities.squat },
      deadlift: { ...maxActivities.deadlift },
    };

    newMaxActivities.bench.weight = convertWeightUnits(
      newMaxActivities.bench.weight,
      newMaxActivities.bench.units,
      units
    );
    newMaxActivities.bench.units = units;
    newMaxActivities.squat.weight = convertWeightUnits(
      newMaxActivities.squat.weight,
      newMaxActivities.squat.units,
      units
    );
    newMaxActivities.squat.units = units;
    newMaxActivities.deadlift.weight = convertWeightUnits(
      newMaxActivities.deadlift.weight,
      newMaxActivities.deadlift.units,
      units
    );
    newMaxActivities.deadlift.units = units;

    return newMaxActivities;
  }, [maxActivities]);

  const combinedWeight = useMemo(
    () =>
      convertedMaxActivites.bench?.weight +
      convertedMaxActivites.squat?.weight +
      convertedMaxActivites.deadlift?.weight,
    [convertedMaxActivites]
  );

  if (loadingUser || loadingWorkout || loadingActivities) {
    return <Loader />;
  }

  const lastWorkoutDateText = lastWorkoutDate
    ? formatDistance(lastWorkoutDate, new Date(), { addSuffix: true })
    : "never";
  const metProfileRequirements =
    maxActivities.bench && maxActivities.squat && maxActivities.deadlift;

  return (
    <div>
      <div>
        <Title style={{ margin: 0 }}>{firstName + " " + lastName}</Title>
        <Text style={{ opacity: 0.6 }}>Last workout {lastWorkoutDateText}</Text>
      </div>

      {metProfileRequirements ? (
        <>
          <Title level={3}>Total Weight</Title>
          <WeightProgress combinedWeight={combinedWeight} units={units} />
          <Title level={3} style={{ marginTop: "48px" }}>
            Breakdown
          </Title>
          <WeightBreakdown
            maxActivities={convertedMaxActivites}
            units={units}
          />
        </>
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "48px" }}
        >
          <div style={{ textAlign: "center", maxWidth: "200px" }}>
            <Text>This user barely works out 😂</Text>
          </div>
        </div>
      )}
    </div>
  );
};
