import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Form } from "antd";
import { useAuthUser } from "../firebase/auth";
import { updateUser } from "../firebase/firestore/users";
import { OnboardingContainer } from "../components/OnboardingContainer";
import { ButtonWithLoader } from "../components/ButtonWithLoader";
import { Loader } from "../components/Loader";

export const SetupPage = () => {
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const firstName = Form.useWatch("firstName", form);
  const lastName = Form.useWatch("lastName", form);
  const [loading, setLoading] = useState(false);

  if (authUser === undefined) {
    return <Loader fullPage />;
  }

  return (
    <OnboardingContainer pageTitle={"A little more..."}>
      <Form
        form={form}
        layout="vertical"
        onFinish={async () => {
          try {
            setLoading(true);
            await updateUser(authUser.uid, { firstName, lastName });
            navigate("/workouts");
          } catch (error) {
            console.log("Error updating user data");
            console.log(error);
            setLoading(false);
          }
        }}
      >
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>
        <ButtonWithLoader loading={loading} htmlType="submit">
          Finish
        </ButtonWithLoader>
      </Form>
    </OnboardingContainer>
  );
};
