import { useState } from "react";
import { Form, InputNumber, Modal } from "antd";
import { createActivity } from "../firebase/firestore/activities";
import { ButtonWithLoader } from "./ButtonWithLoader";

const NewActivityModalContent = ({
  type,
  userId,
  workoutId,
  workoutDate,
  units,
  closeModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const weight = Form.useWatch("weight", form);
  const repCount = Form.useWatch("repCount", form);

  return (
    <Form
      form={form}
      onFinish={async () => {
        setLoading(true);
        await createActivity(
          userId,
          workoutId,
          type,
          weight,
          repCount,
          workoutDate,
          units
        );
        closeModal();
        form.resetFields();
        setLoading(false);
      }}
      layout="vertical"
    >
      <Form.Item
        name="weight"
        label={`Weight (${units})`}
        validateFirst // To not show multiple failed custom validators
        rules={[
          {
            required: true,
            message: "This field is required",
          },
          {
            type: "number",
            message: "This field must be a number",
          },
          {
            type: "number",
            max: 4000,
            message: "Don't lie to yourself 😡",
          },
          {
            type: "number",
            min: 0,
            message: "You can do better than that! 😁",
          },
          {
            validator: (_, weight) => {
              if (!weight) {
                return Promise.resolve();
              }

              const weightArray = weight.toString().split(".");

              if (weightArray.length < 2 || weightArray[1].length < 2) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error("Max one digit after decimal point")
              );
            },
          },
          {
            validator: (_, weight) => {
              if (!weight) {
                return Promise.resolve();
              }

              if (weight.toString().replace(".", "").length <= 4) {
                return Promise.resolve();
              }

              return Promise.reject(new Error("Maximum 4 digits"));
            },
          },
        ]}
      >
        <InputNumber
          type="number"
          inputMode="decimal"
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        name="repCount"
        label="Reps"
        rules={[
          {
            required: true,
            message: "This field is required",
          },
          {
            type: "number",
            message: "This field must be a number",
          },
          {
            type: "number",
            max: 999,
            message: "Don't lie to yourself 😡",
          },
          {
            type: "number",
            min: 0,
            message: "You can do better than that! 😁",
          },
        ]}
      >
        <InputNumber
          type="number"
          inputMode="numeric"
          style={{ width: "100%" }}
        />
      </Form.Item>
      <ButtonWithLoader type="primary" htmlType="submit" loading={loading}>
        Add
      </ButtonWithLoader>
    </Form>
  );
};

export const useNewActivityModal = (
  type,
  userId,
  workoutId,
  workoutDate,
  units
) => {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const NewActivityModalHolder = (
    <Modal
      open={open}
      onCancel={closeModal}
      title={`Add ${type} set`}
      width={300}
      footer={null}
    >
      <NewActivityModalContent
        type={type}
        userId={userId}
        workoutId={workoutId}
        workoutDate={workoutDate}
        units={units}
        closeModal={closeModal}
      />
    </Modal>
  );

  return { openModal, closeModal, NewActivityModalHolder };
};
