import { Link } from "react-router-dom";
import { Typography } from "antd";

const { Title } = Typography;

export const OnboardingContainer = ({ pageTitle, children, ...props }) => (
  <div style={{ padding: "0 20px" }} {...props}>
    <div style={{ textAlign: "center" }}>
      <Link to="/" style={{ textDecoration: "none" }}>
        <Title style={{ marginBottom: "60px" }}>1000 Pound Club</Title>
      </Link>

      <Title level={2}>{pageTitle}</Title>
    </div>

    {children}
  </div>
);
