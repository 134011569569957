import Icon from "@ant-design/icons";
import { Activity } from "../components/Activity";
import { NewActivityButton } from "../components/NewActivityButton";
import { useNewActivityModal } from "../components/NewActivityModal";
import { useAuthUser } from "../firebase/auth";
import BenchIcon from "../assets/bench.svg";
import SquatIcon from "../assets/squat.svg";
import DeadliftIcon from "../assets/deadlift.svg";

const typeToIconMap = {
  bench: <Icon component={() => <img src={BenchIcon} />} />,
  squat: <Icon component={() => <img src={SquatIcon} />} />,
  deadlift: <Icon component={() => <img src={DeadliftIcon} />} />,
};

export const ActivitiesList = ({
  isSummary,
  type,
  workoutId,
  workoutDate,
  units,
  activities = [],
}) => {
  const authUser = useAuthUser();
  const { openModal, NewActivityModalHolder } = useNewActivityModal(
    type,
    authUser?.uid,
    workoutId,
    workoutDate,
    units
  );

  if (isSummary) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "18px",
        }}
      >
        <div>{typeToIconMap[type]}</div>
        <div
          style={{
            overflow: "auto",
            whiteSpace: "nowrap",
          }}
        >
          {activities.map((activity) => (
            <Activity
              activity={activity}
              key={activity.id}
              units={units}
              isSummary
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
        gap: "12px",
        marginBottom: "12px",
      }}
    >
      {activities.map((activity) => (
        <Activity activity={activity} key={activity.id} units={units} />
      ))}
      <NewActivityButton onClick={openModal} />
      {NewActivityModalHolder}
    </div>
  );
};
