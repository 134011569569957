import { useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Input, Typography, Form } from "antd";
import { createAuthUser, loginAuthUser } from "../firebase/auth";
import { createUser } from "../firebase/firestore/users";
import { OnboardingContainer } from "../components/OnboardingContainer";
import { ButtonWithLoader } from "../components/ButtonWithLoader";

const { Text } = Typography;

export const SignupPage = ({ isLogin }) => {
  const [form] = Form.useForm();
  const email = Form.useWatch("email", form);
  const password = Form.useWatch("password", form);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <OnboardingContainer pageTitle={isLogin ? "Login" : "Sign Up"}>
      <Form
        form={form}
        layout="vertical"
        style={{ margin: "20px 0" }}
        onFinish={async () => {
          try {
            setLoading(true);

            if (isLogin) {
              await loginAuthUser(email, password);
            } else {
              const response = await createAuthUser(email, password);
              await createUser(response.user.uid);
            }
          } catch (error) {
            setError(error);
            setLoading(false);
          }
        }}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Email is required",
            },
            {
              required: true,
              type: "email",
              message: "Must be valid email",
            },
          ]}
        >
          <Input placeholder="Email" disabled={loading} />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Password is required",
            },
          ]}
        >
          <Input placeholder="Password" type="password" disabled={loading} />
        </Form.Item>
        <div>
          <Link to={isLogin ? "/signup" : "/login"}>
            <Text>
              {isLogin ? "Create new account" : "Already have an account?"}
            </Text>
          </Link>
        </div>

        <ButtonWithLoader loading={loading} htmlType="submit">
          {isLogin ? "Login" : "Sign up"}
        </ButtonWithLoader>
      </Form>

      {error && <Alert description={error.message} type="error" showIcon />}
    </OnboardingContainer>
  );
};
