import { PlusOutlined } from "@ant-design/icons";
import { ActivityCard } from "./ActivityCard";

export const NewActivityButton = (props) => {
  return (
    <ActivityCard outline {...props}>
      <PlusOutlined />
    </ActivityCard>
  );
};
