import { useMemo } from "react";
import { Form, Typography } from "antd";
import { useAllActivities } from "../firebase/firestore/activities";
import { useAuthUser } from "../firebase/auth";
import { useSubscribeUser } from "../firebase/firestore/users";
import { ActivityTypeSelect } from "../components/ActivityTypeSelect";
import { Loader } from "../components/Loader";
import { WeightOverTimeGraph } from "../components/WeightOverTimeGraph";
import { calculateEffective1RepMax } from "../helpers/calculateEffective1RepMax";
import { convertActivitiesWeightUnits } from "../helpers/convertActivitiesWeightUnits";

const { Text } = Typography;

export const GraphsPage = () => {
  const authUser = useAuthUser();
  const {
    user: { units },
    loading: loadingUser,
  } = useSubscribeUser(authUser?.uid);
  const [form] = Form.useForm();
  const activityType = Form.useWatch("activityType", form);
  const { activities, loading } = useAllActivities(authUser?.uid);
  const convertedActivities = convertActivitiesWeightUnits(activities, units);

  const { benchActivities, squatActivities, deadliftActivities } =
    useMemo(() => {
      const benchActivities = convertedActivities.filter(
        ({ type }) => type === "bench"
      );
      const squatActivities = convertedActivities.filter(
        ({ type }) => type === "squat"
      );
      const deadliftActivities = convertedActivities.filter(
        ({ type }) => type === "deadlift"
      );

      return { benchActivities, squatActivities, deadliftActivities };
    }, [convertedActivities]);

  const generateEffectiveMaxArray = (activities) => {
    if (activities.length > 0) {
      const maxes = [];
      let lastDate = activities[0].workoutDate;
      lastDate.setHours(0, 0, 0, 0);
      let effectiveMax = calculateEffective1RepMax(
        activities[0].weight,
        activities[0].repCount
      );

      activities.forEach((activity) => {
        let currentDate = activity.workoutDate;
        currentDate.setHours(0, 0, 0, 0);

        if (currentDate > lastDate) {
          maxes.push({
            workoutDate: lastDate,
            weight: effectiveMax,
          });
          lastDate = currentDate;
          effectiveMax = calculateEffective1RepMax(
            activity.weight,
            activity.repCount
          );
        }

        if (
          calculateEffective1RepMax(activity.weight, activity.repCount) >
          effectiveMax
        ) {
          effectiveMax = calculateEffective1RepMax(
            activity.weight,
            activity.repCount
          );
        }
      });

      maxes.push({
        workoutDate: lastDate,
        weight: effectiveMax,
      });

      return maxes;
    }
    return [];
  };

  const effectiveOneRepMaxData = useMemo(() => {
    const toXYFormat = ({ workoutDate, weight }) => ({
      x: workoutDate,
      y: weight,
    });

    const bench = generateEffectiveMaxArray(benchActivities).map(toXYFormat);
    const squat = generateEffectiveMaxArray(squatActivities).map(toXYFormat);
    const deadlift =
      generateEffectiveMaxArray(deadliftActivities).map(toXYFormat);

    return { bench, squat, deadlift };
  }, [convertedActivities]);

  return (
    <div>
      <Form form={form} initialValues={{ activityType: "bench" }}>
        <Form.Item name="activityType">
          <ActivityTypeSelect />
        </Form.Item>
      </Form>
      {!authUser || loading || loadingUser ? (
        <Loader />
      ) : effectiveOneRepMaxData[activityType].length === 0 ? (
        <div style={{ textAlign: "center" }}>
          <Text>Work out some more first 🙂</Text>
        </div>
      ) : (
        <WeightOverTimeGraph
          title="Effective 1 Rep Max Over Time"
          chartData={effectiveOneRepMaxData[activityType]}
        />
      )}
    </div>
  );
};
