import { Link } from "react-router-dom";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";

export const BackButton = ({ to }) => {
  return (
    <Link to={to}>
      <Button type="text" style={{ padding: 0, opacity: 0.6 }}>
        <LeftOutlined />
        Back
      </Button>
    </Link>
  );
};
