import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Dropdown } from "antd";
import Icon from "@ant-design/icons";
import { DeleteOutlined, MoreOutlined, EditOutlined } from "@ant-design/icons";
import {
  deleteWorkoutAndActivities,
  useSubscribeWorkout,
} from "../firebase/firestore/workouts";
import { useSubscribeActivities } from "../firebase/firestore/activities";
import { ActivitiesList } from "../components/ActivitiesList";
import { Loader } from "../components/Loader";
import { useEditWorkoutDateModal } from "../components/EditWorkoutDateModal";
import { useAuthUser } from "../firebase/auth";
import { BackButton } from "../components/BackButton";
import BenchIcon from "../assets/bench.svg";
import SquatIcon from "../assets/squat.svg";
import DeadliftIcon from "../assets/deadlift.svg";
import { useSubscribeUser } from "../firebase/firestore/users";

const { Title, Text } = Typography;

export const WorkoutPage = () => {
  const { workoutId } = useParams();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const {
    user: { units },
    loading: loadingUser,
  } = useSubscribeUser(authUser?.uid);
  const [isDeleting, setIsDeleting] = useState(false);
  const { workout, loading: loadingWorkout } = useSubscribeWorkout(workoutId);
  const { activities, loading: loadingActivities } = useSubscribeActivities(
    authUser?.uid,
    workoutId
  );
  const { openModal, EditWorkoutDateModalHolder } = useEditWorkoutDateModal(
    workoutId,
    workout?.workoutDate
  );

  const { benchActivities, squatActivities, deadliftActivities } =
    useMemo(() => {
      const benchActivities = activities.filter(({ type }) => type === "bench");
      const squatActivities = activities.filter(({ type }) => type === "squat");
      const deadliftActivities = activities.filter(
        ({ type }) => type === "deadlift"
      );

      return { benchActivities, squatActivities, deadliftActivities };
    }, [activities]);

  if (loadingWorkout || loadingActivities || isDeleting || loadingUser) {
    return <Loader />;
  }

  const { workoutDate } = workout;

  return (
    <div>
      <Dropdown
        trigger="click"
        menu={{
          items: [
            {
              key: "1",
              label: (
                <div
                  onClick={(e) => {
                    openModal();
                    e.preventDefault();
                  }}
                >
                  <EditOutlined
                    style={{
                      fontSize: "18px",
                      marginRight: "8px",
                    }}
                  />
                  <Text style={{ fontSize: "18px" }}>Edit date</Text>
                </div>
              ),
            },
            {
              key: "2",
              label: (
                <div
                  onClick={async (e) => {
                    setIsDeleting(true);
                    await deleteWorkoutAndActivities(authUser?.uid, workoutId);
                    navigate("/workouts");
                    e.preventDefault();
                  }}
                >
                  <DeleteOutlined
                    style={{
                      fontSize: "18px",
                      marginRight: "8px",
                      color: "red",
                    }}
                  />
                  <Text style={{ fontSize: "18px", color: "red" }}>Delete</Text>
                </div>
              ),
            },
          ],
        }}
        placement="bottomRight"
        size="large"
      >
        <MoreOutlined
          style={{ float: "right", fontSize: "24px", opacity: 0.6 }}
        />
      </Dropdown>
      <BackButton to="/workouts" />

      <Title level={3} style={{ margin: 0 }}>
        Workout on {workoutDate.toLocaleDateString()}
      </Title>
      <Text style={{ opacity: 0.6 }}>
        at{" "}
        {workoutDate.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
        })}
      </Text>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "18px 0 8px 0",
        }}
      >
        <Icon
          component={() => <img src={BenchIcon} />}
          style={{ marginRight: "8px" }}
        />
        <Title level={3} style={{ margin: 0 }}>
          Bench
        </Title>
      </div>

      <ActivitiesList
        activities={benchActivities}
        type="bench"
        workoutId={workoutId}
        workoutDate={workoutDate}
        units={units}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "18px 0 8px 0",
        }}
      >
        <Icon
          component={() => <img src={SquatIcon} />}
          style={{ marginRight: "8px" }}
        />
        <Title level={3} style={{ margin: 0 }}>
          Squat
        </Title>
      </div>
      <ActivitiesList
        activities={squatActivities}
        type="squat"
        workoutId={workoutId}
        workoutDate={workoutDate}
        units={units}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "18px 0 8px 0",
        }}
      >
        <Icon
          component={() => <img src={DeadliftIcon} />}
          style={{ marginRight: "8px" }}
        />
        <Title level={3} style={{ margin: 0 }}>
          Deadlift
        </Title>
      </div>
      <ActivitiesList
        activities={deadliftActivities}
        type="deadlift"
        workoutId={workoutId}
        workoutDate={workoutDate}
        units={units}
      />
      {EditWorkoutDateModalHolder}
    </div>
  );
};
