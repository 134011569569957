import { Progress, Popover } from "antd";

export const WeightProgress = ({
  combinedWeight,
  combinedWeightGoal = 1000,
  units,
}) => {
  const percent = combinedWeight / combinedWeightGoal;
  const roundedPercent = Math.round(percent * 100);

  return (
    <div style={{ display: "flex", justifyContent: "center", margin: "24px" }}>
      <Popover
        content={`${combinedWeight} / ${combinedWeightGoal} lbs`}
        title={`${roundedPercent}% Complete`}
        placement="bottom"
      >
        <Progress
          type="circle"
          percent={(combinedWeight / combinedWeightGoal) * 100}
          format={() => (
            <div style={{ margin: "24px" }}>
              <div>
                {combinedWeight} {units}
              </div>

              {combinedWeight >= combinedWeightGoal && (
                <div style={{ marginTop: "12px" }}>💪</div>
              )}
            </div>
          )}
          size={200}
        />
      </Popover>
    </div>
  );
};
