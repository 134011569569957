import { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  where,
  query,
  onSnapshot,
  orderBy,
  limit,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../setup";
import { convertTimestampsToDates } from "../../helpers/convertTimestampsToDates";
import { convertWeightUnits } from "../../helpers/convertWeightUnits";

const activitiesCollection = collection(db, "activities");

export const useSubscribeActivities = (userId, workoutId) => {
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    if (userId) {
      const q = query(
        activitiesCollection,
        where("userId", "==", userId),
        where("workoutId", "==", workoutId),
        orderBy("created")
      );

      const unsub = onSnapshot(q, (snapshot) => {
        setLoading(true);

        const activities = [];

        snapshot.forEach((doc) => {
          const activity = {
            id: doc.id,
            ...convertTimestampsToDates(doc.data()),
          };

          activities.push(activity);
        });

        setActivities(activities);
        setLoading(false);
      });

      return unsub;
    }
  }, [userId, workoutId]);

  return { activities, loading };
};

export const fetchActivities = async (userId, workoutId) => {
  const q = query(
    activitiesCollection,
    where("userId", "==", userId),
    where("workoutId", "==", workoutId),
    orderBy("created")
  );
  const snapshot = await getDocs(q);

  const activities = [];

  snapshot.forEach((doc) => {
    const activity = {
      id: doc.id,
      ...convertTimestampsToDates(doc.data()),
    };

    activities.push(activity);
  });

  return activities;
};

export const createActivity = async (
  userId,
  workoutId,
  type,
  weight,
  repCount,
  workoutDate,
  units
) => {
  try {
    const createdDate = new Date();
    const docRef = await addDoc(activitiesCollection, {
      userId,
      workoutId,
      type,
      weight,
      repCount,
      workoutDate,
      units,
      created: createdDate,
      modified: createdDate,
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const deleteActivity = async (activityId) => {
  try {
    await deleteDoc(doc(activitiesCollection, activityId));
  } catch (e) {
    console.error("Error deleting document: ", e);
  }
};

export const useMaxActivities = (userId) => {
  const [loading, setLoading] = useState(true);
  const [maxActivities, setMaxActivities] = useState({});

  useEffect(() => {
    const fetch = async () => {
      if (userId) {
        setLoading(true);

        const activityTypes = ["bench", "squat", "deadlift"];
        const maxActivities = {};

        for (const activityType of activityTypes) {
          const lbsQ = query(
            activitiesCollection,
            where("userId", "==", userId),
            where("type", "==", activityType),
            where("units", "==", "lbs"),
            orderBy("weight", "desc"),
            limit(1)
          );
          const kgQ = query(
            activitiesCollection,
            where("userId", "==", userId),
            where("type", "==", activityType),
            where("units", "==", "kg"),
            orderBy("weight", "desc"),
            limit(1)
          );

          const lbsSnapshot = await getDocs(lbsQ);
          const kgSnapshot = await getDocs(kgQ);

          let maxActivity = null;

          lbsSnapshot.forEach((doc) => {
            const activity = {
              id: doc.id,
              ...convertTimestampsToDates(doc.data()),
            };

            if (
              !maxActivity ||
              convertWeightUnits(maxActivity.weight, maxActivity.units, "lbs") <
                convertWeightUnits(activity.weight, activity.units, "lbs")
            ) {
              maxActivity = activity;
            }
          });
          kgSnapshot.forEach((doc) => {
            const activity = {
              id: doc.id,
              ...convertTimestampsToDates(doc.data()),
            };

            if (
              !maxActivity ||
              convertWeightUnits(maxActivity.weight, maxActivity.units, "lbs") <
                convertWeightUnits(activity.weight, activity.units, "lbs")
            ) {
              maxActivity = activity;
            }
          });

          maxActivities[activityType] = maxActivity;
        }

        setMaxActivities(maxActivities);
        setLoading(false);
      }
    };

    fetch();
  }, [userId]);

  return { maxActivities, loading };
};

export const useAllActivities = (userId) => {
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      if (userId) {
        setLoading(true);
        const activities = await fetchAllActivities(userId);
        setActivities(activities);
        setLoading(false);
      }
    };

    fetch();
  }, [userId]);

  return { activities, loading };
};

export const fetchAllActivities = async (userId) => {
  const q = query(
    collection(db, "activities"),
    where("userId", "==", userId),
    orderBy("workoutDate")
  );
  const snapshot = await getDocs(q);

  const activities = [];

  snapshot.forEach((doc) => {
    const activity = {
      id: doc.id,
      ...convertTimestampsToDates(doc.data()),
    };

    activities.push(activity);
  });

  return activities;
};
