import { useState, useEffect } from "react";
import { Typography } from "antd";
import { useAuthUser } from "../firebase/auth";
import { useWorkoutsWithActivities } from "../firebase/firestore/workouts";
import { useSubscribeUser } from "../firebase/firestore/users";
import { WorkoutCard } from "./WorkoutCard";
import { Center } from "../components/Center";
import { Loader } from "./Loader";

const PAGE_SIZE = 5;
const { Text } = Typography;

export const WorkoutsList = () => {
  const authUser = useAuthUser();
  const {
    user: { units },
    loading: loadingUser,
  } = useSubscribeUser(authUser?.uid);
  const [numWorkoutsToRender, setNumWorkoutsToRender] = useState(10);

  const { workoutsWithActivities, loading: loadingWorkouts } =
    useWorkoutsWithActivities(authUser?.uid, numWorkoutsToRender);

  const loadedAllWorkouts =
    workoutsWithActivities.length + PAGE_SIZE < numWorkoutsToRender;

  const handleScroll = () => {
    const notBottomOfPage =
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight;

    if (notBottomOfPage || loadedAllWorkouts || loadingWorkouts) {
      return;
    }

    setNumWorkoutsToRender((prevNum) => {
      return prevNum + PAGE_SIZE;
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loadingWorkouts]);

  if ((loadingWorkouts && workoutsWithActivities.length === 0) || loadingUser) {
    return <Loader />;
  }

  if (workoutsWithActivities.length === 0) {
    return (
      <Center>
        <Text>Go lift some weight! 💪</Text>
      </Center>
    );
  }

  return (
    <div>
      {workoutsWithActivities.map((workout) => (
        <WorkoutCard workout={workout} key={workout.id} units={units} />
      ))}
      {loadingWorkouts && <Loader />}
    </div>
  );
};
