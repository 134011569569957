import { useState, useEffect } from "react";
import {
  collection,
  getDoc,
  getDocs,
  doc,
  where,
  orderBy,
  query,
  onSnapshot,
  writeBatch,
  limit,
  setDoc,
  startAfter,
} from "firebase/firestore";
import { db } from "../setup";
import { convertTimestampsToDates } from "../../helpers/convertTimestampsToDates";
import { hydrateWorkoutsListWithActivities } from "../../helpers/hydrateWorkoutsListWithActivities";

const workoutsCollection = collection(db, "workouts");

export const useWorkout = (workoutId) => {
  const [loading, setLoading] = useState(true);
  const [workout, setWorkout] = useState({});

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const workout = await fetchWorkout(workoutId);
      setWorkout(workout);
      setLoading(false);
    };

    fetch();
  }, [workoutId]);

  return { workout, loading };
};

export const useSubscribeWorkout = (workoutId) => {
  const [loading, setLoading] = useState(true);
  const [workout, setWorkout] = useState({});

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "workouts", workoutId), (snapshot) => {
      setWorkout(convertTimestampsToDates(snapshot.data()));
      setLoading(false);
    });

    return unsub;
  }, [workoutId]);

  return { workout, loading };
};

export const fetchWorkout = async (workoutId) => {
  try {
    const snapshot = await getDoc(doc(db, "workouts", workoutId));
    return convertTimestampsToDates(snapshot.data());
  } catch (e) {
    console.error("Error fetching document: ", e);
  }
};

export const createWorkoutOptimistic = (userId) => {
  try {
    const workoutRef = doc(workoutsCollection);
    const createdDate = new Date();
    setDoc(workoutRef, {
      userId,
      created: createdDate,
      modified: createdDate,
      workoutDate: createdDate,
    });

    console.log("Optimistically created workout with ID: ", workoutRef.id);

    return workoutRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const updateWorkoutDate = async (userId, workoutId, { workoutDate }) => {
  try {
    const modifiedDate = new Date();

    // Create a batched write to commit all deletes at once
    const batch = writeBatch(db);

    // Add activity updates to batch
    const q = query(
      collection(db, "activities"),
      where("userId", "==", userId),
      where("workoutId", "==", workoutId)
    );

    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
      batch.update(doc.ref, {
        workoutDate,
        modified: modifiedDate,
      });
    });

    // Add workout update to batch
    batch.update(doc(db, "workouts", workoutId), {
      workoutDate,
      modified: modifiedDate,
    });

    // Commit the batched write
    await batch.commit();

    console.log("Batch write complete");
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

export const deleteWorkoutAndActivities = async (userId, workoutId) => {
  try {
    // Create a batched write to commit all deletes at once
    const batch = writeBatch(db);

    // Add activity deletes to batch
    const q = query(
      collection(db, "activities"),
      where("userId", "==", userId),
      where("workoutId", "==", workoutId)
    );

    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    // Add workout delete to batch
    batch.delete(doc(db, "workouts", workoutId));

    // Commit the batched write
    await batch.commit();

    console.log("Batch write complete");
  } catch (e) {
    console.error("Error deleting document: ", e);
  }
};

export const useLastWorkout = (userId) => {
  const [loading, setLoading] = useState(true);
  const [workout, setWorkout] = useState({});

  useEffect(() => {
    const fetch = async () => {
      if (userId) {
        setLoading(true);
        const workout = await fetchLastWorkout(userId);
        if (workout) {
          setWorkout(workout);
        }
        setLoading(false);
      }
    };

    fetch();
  }, [userId]);

  return { workout, loading };
};

export const fetchLastWorkout = async (userId) => {
  try {
    const q = query(
      workoutsCollection,
      where("userId", "==", userId),
      orderBy("workoutDate", "desc"),
      limit(1)
    );
    const snapshot = await getDocs(q);

    const workouts = [];

    snapshot.forEach((doc) => {
      const workout = {
        id: doc.id,
        ...convertTimestampsToDates(doc.data()),
      };

      workouts.push(workout);
    });

    return workouts[0];
  } catch (e) {
    console.error("Error fetching document: ", e);
  }
};

export const useWorkouts = (userId) => {
  const [loading, setLoading] = useState(true);
  const [workouts, setWorkouts] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      if (userId) {
        setLoading(true);
        const workouts = await fetchWorkouts(userId);
        setWorkouts(workouts);
        setLoading(false);
      }
    };

    fetch();
  }, [userId]);

  return { workouts, loading };
};

export const useWorkoutsWithActivities = (userId, numItems) => {
  const [loading, setLoading] = useState(true);
  const [workoutsWithActivities, setWorkoutsWithActivities] = useState([]);
  const [lastWorkout, setLastWorkout] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      if (userId && numItems > workoutsWithActivities.length) {
        setLoading(true);

        const workouts = [];
        const newWorkoutActivities = {};

        // Query workouts
        let workoutsQuery;
        if (workoutsWithActivities.length == 0) {
          workoutsQuery = query(
            workoutsCollection,
            where("userId", "==", userId),
            orderBy("workoutDate", "desc"),
            limit(numItems)
          );
        } else {
          workoutsQuery = query(
            workoutsCollection,
            where("userId", "==", userId),
            orderBy("workoutDate", "desc"),
            startAfter(lastWorkout),
            limit(numItems - workoutsWithActivities.length)
          );
        }

        const workoutsSnapshot = await getDocs(workoutsQuery);

        if (workoutsSnapshot.docs.length === 0) {
          // If there are no more workouts, just return
          setLoading(false);
          return;
        }

        setLastWorkout(workoutsSnapshot.docs[workoutsSnapshot.docs.length - 1]);

        workoutsSnapshot.forEach((doc) => {
          workouts.push({
            id: doc.id,
            ...convertTimestampsToDates(doc.data()),
          });
        });

        const workoutIds = workouts.map((workout) => workout.id);

        // Fetch activities for the workouts
        const activitiesQuery = query(
          collection(db, "activities"),
          where("userId", "==", userId),
          where("workoutId", "in", workoutIds),
          orderBy("created")
        );
        const activitiesSnapshot = await getDocs(activitiesQuery);
        activitiesSnapshot.forEach((doc) => {
          const activity = {
            id: doc.id,
            ...convertTimestampsToDates(doc.data()),
          };
          const { workoutId } = activity;

          if (!newWorkoutActivities[workoutId]) {
            newWorkoutActivities[workoutId] = [];
          }

          newWorkoutActivities[workoutId].push(activity);
        });

        const newWorkoutsWithActivities = hydrateWorkoutsListWithActivities(
          workouts,
          newWorkoutActivities
        );

        setWorkoutsWithActivities((prev) => [
          ...prev,
          ...newWorkoutsWithActivities,
        ]);
        setLoading(false);
      }
    };

    fetch();
  }, [userId, numItems]);

  return { workoutsWithActivities, loading };
};

export const fetchWorkouts = async (userId) => {
  const q = query(
    workoutsCollection,
    where("userId", "==", userId),
    orderBy("workoutDate", "desc")
  );
  const snapshot = await getDocs(q);

  const workouts = [];

  snapshot.forEach((doc) => {
    const workout = {
      id: doc.id,
      ...convertTimestampsToDates(doc.data()),
    };

    workouts.push(workout);
  });

  return workouts;
};
