import { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "./setup";

export const createAuthUser = async (email, password) => {
  try {
    const user = await createUserWithEmailAndPassword(auth, email, password);
    return user;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("Failed to create user");
    console.log(errorCode, ": ", errorMessage);

    if (errorCode === "auth/weak-password") {
      throw new Error("Password must be at least 6 characters");
    } else if (errorCode === "auth/email-already-in-use") {
      throw new Error("An account already exists with this email");
    }

    throw new Error("Failed to create user");
  }
};

export const loginAuthUser = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("Failed to login user");
    console.log(errorCode, ": ", errorMessage);

    if (errorCode === "auth/wrong-password") {
      throw new Error("The password is incorrect");
    } else if (errorCode === "auth/user-not-found") {
      throw new Error("An account with this email does not exist");
    } else if (errorCode === "auth/too-many-requests") {
      throw new Error(
        "This account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
      );
    }

    throw new Error("Failed to login user");
  }
};

export const useAuthUser = () => {
  const [authUser, setAuthUser] = useState(undefined);

  useEffect(() => {
    onAuthStateChanged(auth, (authUser) => {
      setAuthUser(authUser);
    });
  }, []);

  return authUser;
};

export const logoutAuthUser = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("Failed to logout user");
    console.log(errorCode, ": ", errorMessage);
    throw new Error("Failed to logout user");
  }
};
