import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export const WeightBreakdown = ({ maxActivities, units }) => {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        pointLabels: {
          font: {
            size: 12,
          },
        },
      },
    },
  };

  const data = {
    labels: [
      ["Bench", `${maxActivities.bench.weight} ${units}`],
      ["Squat", `${maxActivities.squat.weight} ${units}`],
      ["Deadlift", `${maxActivities.deadlift.weight} ${units}`],
    ],
    datasets: [
      {
        data: [
          maxActivities.bench.weight,
          maxActivities.squat.weight,
          maxActivities.deadlift.weight,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ width: "100%" }}>
      <Radar options={options} data={data} />
    </div>
  );
};
