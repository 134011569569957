import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Typography, Popconfirm, message } from "antd";
import { SettingOutlined, ShareAltOutlined } from "@ant-design/icons";
import { formatDistance } from "date-fns";
import { useAuthUser } from "../firebase/auth";
import { useSubscribeUser, updateUser } from "../firebase/firestore/users";
import { useLastWorkout } from "../firebase/firestore/workouts";
import { useMaxActivities } from "../firebase/firestore/activities";
import { Loader } from "../components/Loader";
import { WeightProgress } from "../components/WeightProgress";
import { WeightBreakdown } from "../components/WeightBreakdown";
import { convertWeightUnits } from "../helpers/convertWeightUnits";

const { Title, Text } = Typography;

export const ProfilePage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const authUser = useAuthUser();
  const {
    user: { firstName, lastName, isPublicProfile, combinedWeightGoal, units },
    loading: loadingUser,
  } = useSubscribeUser(authUser?.uid);
  const {
    workout: { workoutDate: lastWorkoutDate },
    loading: loadingWorkout,
  } = useLastWorkout(authUser?.uid);
  const { maxActivities, loading: loadingActivities } = useMaxActivities(
    authUser?.uid
  );

  const convertedMaxActivites = useMemo(() => {
    if (
      !maxActivities.bench ||
      !maxActivities.squat ||
      !maxActivities.deadlift
    ) {
      return {};
    }

    const newMaxActivities = {
      bench: { ...maxActivities.bench },
      squat: { ...maxActivities.squat },
      deadlift: { ...maxActivities.deadlift },
    };

    newMaxActivities.bench.weight = convertWeightUnits(
      newMaxActivities.bench.weight,
      newMaxActivities.bench.units,
      units
    );
    newMaxActivities.bench.units = units;
    newMaxActivities.squat.weight = convertWeightUnits(
      newMaxActivities.squat.weight,
      newMaxActivities.squat.units,
      units
    );
    newMaxActivities.squat.units = units;
    newMaxActivities.deadlift.weight = convertWeightUnits(
      newMaxActivities.deadlift.weight,
      newMaxActivities.deadlift.units,
      units
    );
    newMaxActivities.deadlift.units = units;

    return newMaxActivities;
  }, [maxActivities]);

  const combinedWeight = useMemo(
    () =>
      convertedMaxActivites.bench?.weight +
      convertedMaxActivites.squat?.weight +
      convertedMaxActivites.deadlift?.weight,
    [convertedMaxActivites]
  );

  if (!authUser || loadingUser || loadingWorkout || loadingActivities) {
    return <Loader />;
  }

  const lastWorkoutDateText = lastWorkoutDate
    ? formatDistance(lastWorkoutDate, new Date(), { addSuffix: true })
    : "never";
  const metProfileRequirements =
    maxActivities.bench && maxActivities.squat && maxActivities.deadlift;

  return (
    <div>
      {contextHolder}
      <Link to="/profile/settings">
        <SettingOutlined
          style={{ color: "black", float: "right", fontSize: "24px" }}
        />
      </Link>
      {isPublicProfile ? (
        <ShareAltOutlined
          onClick={() => {
            navigator.clipboard.writeText(
              `https://1000pound.com/profile/${authUser.uid}`
            );
            messageApi.open({
              type: "success",
              content: "Copied to clipboard",
            });
          }}
          style={{ float: "right", fontSize: "24px", marginRight: "18px" }}
        />
      ) : (
        <Popconfirm
          placement="bottomRight"
          title="Make profile public?"
          description={
            <div style={{ width: "250px" }}>
              In order to share your profile, it needs to be a public profile.
            </div>
          }
          onConfirm={() => {
            updateUser(authUser.uid, { isPublicProfile: true });
            navigator.clipboard.writeText(
              `https://1000pound.com/profile/${authUser.uid}`
            );
            messageApi.open({
              type: "success",
              content: "Copied to clipboard",
            });
          }}
          okText="Yes"
          cancelText="No"
          style={{ width: "300px" }}
        >
          <ShareAltOutlined
            style={{
              float: "right",
              fontSize: "24px",
              marginRight: "18px",
            }}
          />
        </Popconfirm>
      )}

      <div>
        <Title style={{ margin: 0 }}>{firstName + " " + lastName}</Title>
        <Text style={{ opacity: 0.6 }}>Last workout {lastWorkoutDateText}</Text>
      </div>

      {metProfileRequirements ? (
        <>
          <Title level={3}>Total Weight</Title>
          <WeightProgress
            combinedWeight={combinedWeight}
            combinedWeightGoal={combinedWeightGoal}
            units={units}
          />
          <Title level={3} style={{ marginTop: "48px" }}>
            Breakdown
          </Title>
          <WeightBreakdown
            maxActivities={convertedMaxActivites}
            units={units}
          />
        </>
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "48px" }}
        >
          <div style={{ textAlign: "center", maxWidth: "200px" }}>
            <Text>
              Do 1 bench, 1 squat, and 1 deadlift to unlock this page 🏋️‍♂️
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};
