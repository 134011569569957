import { useState } from "react";
import { Form, Modal } from "antd";
import { ButtonWithLoader } from "./ButtonWithLoader";
import { updateWorkoutDate } from "../firebase/firestore/workouts";
import { DatePicker } from "../components/DatePicker";
import { useAuthUser } from "../firebase/auth";

const EditWorkoutDateModalContent = ({
  workoutId,
  workoutDate,
  closeModal,
}) => {
  const authUser = useAuthUser();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const newWorkoutDate = Form.useWatch("newWorkoutDate", form);
  const newWorkoutTime = Form.useWatch("newWorkoutTime", form);

  return (
    <Form
      form={form}
      initialValues={{
        newWorkoutDate: workoutDate,
        newWorkoutTime: workoutDate,
      }}
      onFinish={async () => {
        setLoading(true);
        await updateWorkoutDate(authUser.uid, workoutId, {
          workoutDate: new Date(
            newWorkoutDate.getFullYear(),
            newWorkoutDate.getMonth(),
            newWorkoutDate.getDate(),
            newWorkoutTime.getHours(),
            newWorkoutTime.getMinutes()
          ),
        });
        closeModal();
        form.resetFields();
        setLoading(false);
      }}
      layout="vertical"
    >
      <Form.Item label="Date" required>
        <Form.Item
          name="newWorkoutDate"
          noStyle
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <DatePicker format="MM/DD/YYYY" style={{ marginRight: "12px" }} />
        </Form.Item>
        <Form.Item
          name="newWorkoutTime"
          noStyle
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <DatePicker picker="time" format="KK:mm a" />
        </Form.Item>
      </Form.Item>
      <ButtonWithLoader type="primary" htmlType="submit" loading={loading}>
        Save
      </ButtonWithLoader>
    </Form>
  );
};

export const useEditWorkoutDateModal = (workoutId, workoutDate) => {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const EditWorkoutDateModalHolder = (
    <Modal
      open={open}
      onCancel={closeModal}
      title={`Edit workout date`}
      width={320}
      footer={null}
    >
      <EditWorkoutDateModalContent
        workoutId={workoutId}
        workoutDate={workoutDate}
        closeModal={closeModal}
      />
    </Modal>
  );

  return { openModal, closeModal, EditWorkoutDateModalHolder };
};
