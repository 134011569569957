import { useState } from "react";
import {
  Input,
  Form,
  Typography,
  Button,
  Switch,
  message,
  InputNumber,
  Select,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useAuthUser, logoutAuthUser } from "../firebase/auth";
import { updateUser, useSubscribeUser } from "../firebase/firestore/users";
import { ButtonWithLoader } from "../components/ButtonWithLoader";
import { Loader } from "../components/Loader";
import { BackButton } from "../components/BackButton";

const { Title } = Typography;

export const SettingsPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const authUser = useAuthUser();
  const {
    user: { firstName, lastName, isPublicProfile, combinedWeightGoal, units },
    loading: loadingUser,
  } = useSubscribeUser(authUser?.uid);

  const [form] = Form.useForm();
  const newFirstName = Form.useWatch("firstName", form);
  const newLastName = Form.useWatch("lastName", form);
  const newIsPublicProfile = Form.useWatch("isPublicProfile", form);
  const newCombinedWeightGoal = Form.useWatch("combinedWeightGoal", form);
  const newUnits = Form.useWatch("units", form);
  const [loading, setLoading] = useState(false);

  if (!authUser || loadingUser) {
    return <Loader />;
  }

  return (
    <div>
      {contextHolder}
      <BackButton to="/profile" />

      <Title style={{ marginTop: 0 }}>Settings</Title>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          firstName,
          lastName,
          isPublicProfile,
          combinedWeightGoal,
          units,
        }}
        onFinish={async () => {
          try {
            setLoading(true);
            await updateUser(authUser.uid, {
              firstName: newFirstName,
              lastName: newLastName,
              isPublicProfile: newIsPublicProfile,
              combinedWeightGoal: newCombinedWeightGoal,
              units: newUnits,
            });
            messageApi.open({
              type: "success",
              content: "Settings saved!",
            });
            form.resetFields();
            setLoading(false);
          } catch (error) {
            console.log("Error updating user data");
            console.log(error);
            setLoading(false);
          }
        }}
        style={{ marginBottom: "24px" }}
      >
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          name="combinedWeightGoal"
          label="Total Weight Goal"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
            {
              type: "number",
              message: "This field must be a number",
            },
            {
              type: "number",
              max: 10000,
              message: "Be realistic 🙂",
            },
            {
              type: "number",
              min: 100,
              message: "You can do better than that! 😁",
            },
          ]}
        >
          <InputNumber
            type="number"
            inputMode="numeric"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          name="units"
          label="Units"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Select
            options={[
              { value: "lbs", label: "Pounds (lbs)" },
              { value: "kg", label: "Kilograms (kg)" },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="isPublicProfile"
          label="Is profile public?"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Switch />
        </Form.Item>
        <ButtonWithLoader loading={loading} htmlType="submit">
          Save
        </ButtonWithLoader>
      </Form>
      <a href="https://forms.gle/VKfk5DDkG84WazLw8" target="_blank">
        <Button
          type="primary"
          size="large"
          style={{ width: "100%", margin: "12px 0" }}
        >
          <SendOutlined />
          Share Feedback
        </Button>
      </a>

      <Button
        danger
        type="text"
        size="large"
        onClick={logoutAuthUser}
        style={{ width: "100%" }}
      >
        Logout
      </Button>
    </div>
  );
};
