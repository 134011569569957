export const hydrateWorkoutsListWithActivities = (
  workouts,
  workoutActivities
) => {
  const hydratedWorkouts = workouts.map((workout) => {
    const activities = workoutActivities[workout.id] || [];

    return {
      benchActivities: activities.filter(({ type }) => type === "bench"),
      squatActivities: activities.filter(({ type }) => type === "squat"),
      deadliftActivities: activities.filter(({ type }) => type === "deadlift"),
      ...workout,
    };
  });

  return hydratedWorkouts;
};
