export const convertTimestampsToDates = (object) => {
  const newObject = { ...object };

  if (newObject.created) {
    newObject.created = newObject.created.toDate();
  }
  if (newObject.modified) {
    newObject.modified = newObject.modified.toDate();
  }
  if (newObject.workoutDate) {
    newObject.workoutDate = newObject.workoutDate.toDate();
  }

  return newObject;
};
