import { Typography, Select } from "antd";

const { Title } = Typography;

export const ActivityTypeSelect = (props) => {
  return (
    <Select
      options={[
        {
          value: "bench",
          label: (
            <Title level={3} style={{ margin: 0, lineHeight: "40px" }}>
              Bench
            </Title>
          ),
        },
        {
          value: "squat",
          label: (
            <Title level={3} style={{ margin: 0, lineHeight: "40px" }}>
              Squat
            </Title>
          ),
        },
        {
          value: "deadlift",
          label: (
            <Title level={3} style={{ margin: 0, lineHeight: "40px" }}>
              Deadlift
            </Title>
          ),
        },
      ]}
      size="large"
      style={{ width: "150px" }}
      {...props}
    />
  );
};
