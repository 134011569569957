import { useState, useEffect } from "react";
import { doc, setDoc, updateDoc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../setup";
import { convertTimestampsToDates } from "../../helpers/convertTimestampsToDates";

export const useSubscribeUser = (userId) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  useEffect(() => {
    if (userId) {
      const unsub = onSnapshot(doc(db, "users", userId), (snapshot) => {
        setUser(convertTimestampsToDates(snapshot.data()));
        setLoading(false);
      });

      return unsub;
    }
  }, [userId]);

  return { user, loading };
};

export const fetchUser = async (userId) => {
  try {
    const snapshot = await getDoc(doc(db, "users", userId));
    return snapshot.data();
  } catch (e) {
    console.error("Error fetching document: ", e);
  }
};

export const createUser = async (userId) => {
  try {
    const createdDate = new Date();
    await setDoc(doc(db, "users", userId), {
      created: createdDate,
      modified: createdDate,
      isPublicProfile: false,
      combinedWeightGoal: 1000,
      units: "lbs",
    });
    console.log("Document written with ID: ", userId);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const updateUser = async (
  userId,
  { firstName, lastName, isPublicProfile, combinedWeightGoal, units }
) => {
  try {
    const modifiedDate = new Date();
    await updateDoc(doc(db, "users", userId), {
      ...(firstName !== undefined ? { firstName } : {}),
      ...(lastName !== undefined ? { lastName } : {}),
      ...(isPublicProfile !== undefined ? { isPublicProfile } : {}),
      ...(combinedWeightGoal !== undefined ? { combinedWeightGoal } : {}),
      ...(units !== undefined ? { units } : {}),
      modified: modifiedDate,
    });
    console.log("Document written with ID: ", userId);
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};
