import { Link } from "react-router-dom";
import styles from "./RootPage.module.css";
import heroImage from "../assets/hero.png";
import trackWorkoutsImage from "../assets/trackWorkouts.png";
import streamlineWorkout from "../assets/streamlineWorkout.png";
import trackProgress from "../assets/trackProgress.gif";
import analyzeData from "../assets/analyzeData.png";
import flexOnFriends from "../assets/flexOnFriends.png";

export const RootPage = () => {
  return (
    <div className={styles.rootPage}>
      {/* Header */}
      <div className={styles.header}>
        <p className={styles.logo}>
          1000<span style={{ color: "#9740C0" }}>Pound</span>
        </p>
        <Link to="/login">
          <button className={styles.loginButton}>LOGIN</button>
        </Link>
      </div>

      {/* Content */}
      <div className={styles.content}>
        <div className={styles.marginWrapper}>
          {/* Hero */}
          <div className={styles.hero}>
            <div className={styles.heroTextContainer}>
              <h1 className={styles.heroHeading}>Showcase Your Strength</h1>
              <h2 className={styles.heroSubHeading}>
                The ultimate powerlifting profile
              </h2>
              <Link to="/signup">
                <button className={styles.heroButton}>CREATE PROFILE</button>
              </Link>
              <p className={styles.heroData}>
                🏋️‍♀️ <span style={{ fontWeight: 700 }}>200+</span> Profiles
                Created
              </p>
              <p className={styles.heroData}>
                💪 <span style={{ fontWeight: 700 }}>3,000,000+</span> Pounds
                Lifted
              </p>
            </div>
            <div className={styles.heroImageContainer}>
              <img src={heroImage} className={styles.heroImage} />
            </div>
          </div>

          {/* Section 1 */}
          <div className={styles.section}>
            <div className={styles.sectionHeaderWrapper}>
              <div className={styles.sectionHeader}>
                <p className={styles.step}>STEP 1</p>
                <h3 className={styles.sectionHeading}>Track your lifts</h3>
                <div className={styles.sectionUnderline} />
              </div>
            </div>

            <div className={styles.featureContainer}>
              {/* Section 1.1 */}
              <div className={styles.feature}>
                <div className={styles.featureImageContainer}>
                  <img
                    src={trackWorkoutsImage}
                    style={{ width: "100%", maxWidth: "400px" }}
                  />
                </div>
                <h4 className={styles.featureHeading}>
                  Lift harder than last time
                </h4>
                <p className={styles.featureSubHeading}>
                  View your previous workouts to set goals for yourself.
                </p>
              </div>

              {/* Section 1.2 */}
              <div className={styles.feature}>
                <div className={styles.featureImageContainer}>
                  <img src={streamlineWorkout} style={{ width: "80%" }} />
                </div>
                <h4 className={styles.featureHeading}>
                  Streamline your workout log
                </h4>
                <p className={styles.featureSubHeading}>
                  Just 2 clicks to add a set. Logging has never been easier.
                </p>
              </div>
            </div>
          </div>

          {/* Section 2 */}
          <div className={styles.section}>
            <div className={styles.sectionHeaderWrapper}>
              <div className={styles.sectionHeader}>
                <p className={styles.step} style={{ color: "#A37877" }}>
                  STEP 2
                </p>
                <h3 className={styles.sectionHeading}>See your progress</h3>
                <div
                  className={styles.sectionUnderline}
                  style={{ backgroundColor: "#A37877" }}
                />
              </div>
            </div>

            <div className={styles.featureContainer}>
              {/* Section 2.1 */}
              <div className={styles.feature}>
                <div className={styles.featureImageContainer}>
                  <img
                    src={trackProgress}
                    style={{
                      borderRadius: "40px",
                      width: "67%",
                      maxWidth: "300px",
                    }}
                  />
                </div>
                <h4 className={styles.featureHeading}>Visualize your goal</h4>
                <p className={styles.featureSubHeading}>
                  Your total weight is front and center. Celebrate as you hit
                  your goals.
                </p>
              </div>

              {/* Section 2.2 */}
              <div className={styles.feature}>
                <div className={styles.featureImageContainer}>
                  <img src={analyzeData} style={{ width: "100%" }} />
                </div>
                <h4 className={styles.featureHeading}>Analyze lift data</h4>
                <p className={styles.featureSubHeading}>
                  See real improvement ofver time. Identify which lift is
                  keeping you behind.
                </p>
              </div>
            </div>
          </div>

          {/* Section 3 */}
          <div className={styles.section}>
            <div className={styles.sectionHeaderWrapper}>
              <div className={styles.sectionHeader}>
                <p className={styles.step} style={{ color: "#B4D200" }}>
                  STEP 3
                </p>
                <h3 className={styles.sectionHeading}>Show the competition</h3>
                <div
                  className={styles.sectionUnderline}
                  style={{ backgroundColor: "#B4D200" }}
                />
              </div>
            </div>

            {/* Section 3.1 */}
            <div className={styles.featureContainer}>
              <div className={styles.feature}>
                <div className={styles.featureImageContainer}>
                  <img
                    src={flexOnFriends}
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
                <h4 className={styles.featureHeading}>
                  Share your profile with friends
                </h4>
                <p className={styles.featureSubHeading}>
                  Don't let anyone forget that you're the best. Flex on 'em.
                </p>
              </div>
            </div>
          </div>

          {/* CTA */}
          <div className={styles.cta}>
            <div className={styles.ctaTextWrapper}>
              <h2 className={styles.ctaHeading}>
                Ready to level up your strength?
              </h2>
              <p className={styles.ctaSubHeading}>
                Dominate the competition today
              </p>
            </div>
            <div className={styles.ctaButtonWrapper}>
              <Link to="/signup">
                <button className={styles.ctaButton}>GET STARTED</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
