import { convertWeightUnits } from "./convertWeightUnits";

export const convertActivitiesWeightUnits = (activities, preferredUnits) => {
  return activities.map((activity) => {
    const newActivity = { ...activity };
    newActivity.weight = convertWeightUnits(
      activity.weight,
      activity.units,
      preferredUnits
    );
    newActivity.units = preferredUnits;

    return newActivity;
  });
};
