import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAhgE65ow9PKOToYv6x5CEYe4Ne6GAKat0",
  authDomain: "poundclub-a10c0.firebaseapp.com",
  projectId: "poundclub-a10c0",
  storageBucket: "poundclub-a10c0.appspot.com",
  messagingSenderId: "146983048926",
  appId: "1:146983048926:web:6c77ad0443db0ecb24544f",
  measurementId: "G-JH356227YF",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
