import { Card, Typography } from "antd";
import { Link } from "react-router-dom";
import { ActivitiesList } from "./ActivitiesList";
import { Loader } from "./Loader";

const { Title, Text } = Typography;

export const WorkoutCard = ({
  workout: {
    id: workoutId,
    workoutDate,
    benchActivities,
    squatActivities,
    deadliftActivities,
  },
  loadingActivities,
  units,
}) => {
  return (
    <div>
      <Link to={`/workouts/${workoutId}`} style={{ textDecoration: "none" }}>
        <Card
          size="small"
          style={{
            marginBottom: "18px",
            padding: "0 12px",
            borderRadius: "20px",
          }}
        >
          <div style={{ marginBottom: "12px" }}>
            <Title level={4} style={{ margin: 0 }}>
              Workout on {workoutDate.toLocaleDateString()}
            </Title>
            <Text style={{ opacity: 0.6 }}>
              at{" "}
              {workoutDate.toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
              })}
            </Text>
          </div>

          {loadingActivities ? (
            <Loader />
          ) : (
            <>
              {benchActivities.length > 0 && (
                <ActivitiesList
                  isSummary
                  type="bench"
                  activities={benchActivities}
                  units={units}
                />
              )}
              {squatActivities.length > 0 && (
                <ActivitiesList
                  isSummary
                  type="squat"
                  activities={squatActivities}
                  units={units}
                />
              )}
              {deadliftActivities.length > 0 && (
                <ActivitiesList
                  isSummary
                  type="deadlift"
                  activities={deadliftActivities}
                  units={units}
                />
              )}
            </>
          )}
        </Card>
      </Link>
    </div>
  );
};
